import React from "react";
import { Link, navigate } from "gatsby"
import Axios from "axios"
import { setItem, isLoggedIn } from "../../services/auth"
import AlertError from "../../components/alert/error";
import SocialLogin from "../../components/socialLogin";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: ""
    };
    this.onPop = this.onLoginSocial.bind(this);
    this.DontKnowThis = this.onRegister.bind(this)
  }
  onLoginSocial = (e, loginType) => {
    //lambda url
    var url = `/.netlify/functions/auth/${loginType}`;
    var win = typeof window !== `undefined` ? window : null;
    var n = win.open(url, "_self");
    if (n == null) {
      return true;
    }
    return false;
  };

  onRegister = (e) => {
    e.preventDefault();
    console.log(e);
    const data = {
      email: e.target.email.value,
      name: e.target.name.value,
      password: e.target.password.value,
      password_confirmation: e.target.password_confirmation.value
    };
    
    Axios.post('https://api.gogoblock.io/api/register', data)
        .then((result) => {
            console.log(result);
            if(result.data.success){
              setItem("user", JSON.stringify(result.data.user));
              setItem("token", JSON.stringify(result.data.token))
              navigate(`/member/account`)
            }else{
              this.setState(state => ({
                error: true,
                errorMessage: result.data.errors[0]
              }));
            }
        }).catch((err) => {
            console.error(err);
        });
  };
  render() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-gray-50">
          <body className="h-full">
          ```
        */}
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Register</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <Link to="/auth/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                login
              </Link>
            </p>
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form 
                className="space-y-6" 
                method="POST"
                onSubmit={e => this.onRegister(e)}
                >

                  {this.state.error &&
                    <AlertError title="Error :" text={this.state.errorMessage} />
                  }

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password Confirmation
                  </label>
                  <div className="mt-1">
                    <input
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Remember me
                    </label>
                  </div>
  
                  <div className="text-sm">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>
  
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>
  
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
  
                <SocialLogin />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default RegisterPage;